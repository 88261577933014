/* eslint-disable react/destructuring-assignment */
import React from "react";
import { navigate } from "gatsby";
import axios from "axios";
import { v4 } from "uuid";
import Box from "@material-ui/core/Box";
import Container from "@material-ui/core/Container";
import Typography from "@material-ui/core/Typography";
import SEO from "./seo";

export default class TermsOfUse extends React.Component {
  constructor() {
    super();
    this.handleSubmit = this.handleSubmit.bind(this);
    this.scrollToMyRef = this.scrollToMyRef.bind(this);
    this.state = {
      Location: "",
      Name: "",
      Email: "",
      Company: "",
      Phone: "",
      Comment: "",
    };
  }

  scrollToMyRef() {
    this.node.scrollIntoView();
  }

  handleSubmit(event) {
    event.preventDefault();

    const data = {
      name: this.state.Name,
      email: this.state.Email,
      company: this.state.Company,
      location: this.state.Location,
      phone: this.state.Phone,
      note: this.state.Comment,
    };
    axios.put(
      `https://lablog-62234.firebaseio.com/request-whitepaper/${v4()}.json`,
      { data }
    );

    navigate("/whitepaper-form-submitted/", {
      state: { data },
    });
  }

  render() {
    return (
      <>
        <SEO
          title="Terms Of Use | Legal"
          description="At LabLog, we&#039;re making the lab a better place and loving every minute of it."
        />

        <Container maxWidth="md">
          <Typography component="div">
            <Box
              textAlign="center"
              component="h1"
              m={1}
              fontFamily="DM Serif Text"
              fontWeight="700"
              color="#3d3d3d"
              fontSize="h2.fontSize"
            >
              Terms of use
            </Box>
            <br />
            <Box
              textAlign="center"
              component="h2"
              m={1}
              fontFamily="DM Serif Text"
              fontWeight="700"
              color="#3d3d3d"
              fontSize="h6.fontSize"
            >
              Effective date: June 30, 2021
            </Box>
            <br />
            <Box color="#3d3d3d" component="p" fontSize="h6.fontSize">
              <strong>What Are the Terms of Service?</strong>
              <br />
              The Terms of Service constitutes a contract between LabLog and
              you. These User Terms of Use (the “User Terms”) govern your access
              and use of the LabLog apps and web platform (the “Services”).
              Please read them carefully. We are happy that you’re here.
            </Box>
            <br />
            <Box color="#3d3d3d" component="p" fontSize="h6.fontSize">
              These User Terms are Legally Binding
              <br />
              <br />
              These User Terms are a legally binding contract between you and
              us. As part of these User Terms, you agree to comply with the most
              recent version of our Acceptable Use Policy, which is incorporated
              by reference into these User Terms. If you access or use the
              Services, or continue accessing or using the Services after being
              notified of a change to the User Terms or the Acceptable Use
              Policy, you confirm that you have read, understand and agree to be
              bound by the User Terms and the Acceptable Use Policy. “We”, “our”
              and “us” currently refers to the applicable LabLog entity in the
              Contract (defined below).
              <br />
              <br />
              By using the Service, you acknowledge, accept and agree with all
              provisions of the Privacy Policy, including, without limitation,
              the use and treatment of the text, images, and other data you
              choose to input, upload, or store in LabLog (collectively,
              “Content”) and your personal information in accordance with such
              Privacy Policy.
              <br />
              <br />
              <strong>Who is “Customer”?</strong>
              <br />
              “Customer” is the organization that you represent in agreeing to
              the Contract. If your LabLog account is being set up by someone
              who is not formally affiliated with an organization, Customer is
              the individual who gives you access to your account and the LabLog
              system.
              <br />
              <br />
              <br />
              <strong>CONTENT RIGHTS</strong>
              <br />
              While you own the Content you store within the LabLog service
              (subject to third party rights), you acknowledge and agree that
              LabLog (and our licensors) own(s) all legal right, title and
              interest in and to the Service, including, without limitation, all
              software that is part of the Service and all LabLog software
              deployed by you or a third party to enable capturing of Content
              originating outside the Service, such as LabLog Scannable, LabLog
              Web Clipper, the Site Memory widget or any of the LabLog software
              applications for compatible computing devices that enable access
              and use of the Service through such device (the “LabLog
              Software”).
              <br />
              <br />
              <strong>INTELLECTUAL PROPERTY RIGHTS</strong>
              <br />
              In agreeing to these Terms, you also agree that the rights in the
              Service and LabLog Software, including all intellectual property
              rights, such as trademarks, patents, designs and copyrights, are
              protected by one or more of copyright, trademark, patent, trade
              secret and other laws, regulations and treaties, in addition to
              these Terms and any Separate Agreement. In particular, you agree
              to not modify, create derivative works of, decompile or otherwise
              attempt to extract source code from any LabLog Software, unless
              you are expressly permitted to do so under an open source license,
              we give you express written permission or you are otherwise
              legally permitted to do so notwithstanding this prohibition.
              <br />
              <br />
              <strong>RIGHT TO MODIFY THE SERVICE</strong>
              <br />
              We retain the right, in our sole discretion, to implement new
              elements as part of and/or ancillary to the Service, including
              changes that may affect the previous mode of operation of the
              Service or LabLog Software. We expect that any such modifications
              will enhance the overall Service, but it is possible that you may
              not agree with us. We also reserve the right to establish limits
              to the nature or size of storage available to you, the number of
              transmissions, the ability to send or receive email messages, the
              nature or size of any index or library information, the nature of,
              or your continued ability to access or distribute, your Content
              and other data, and impose other limitations at any time, with or
              without notice. For example, if you use LabLog Basic, you will not
              enjoy all of the benefits provided to subscribers of LabLog Plus,
              LabLog Premium or LabLog Business. You also acknowledge that a
              variety of LabLog actions may impair or prevent you from accessing
              your Content or using the Service at certain times and/or in the
              same way, for limited periods or permanently, and agree that
              LabLog has no responsibility or liability as a result of any such
              actions or results, including, without limitation, for the
              deletion of, or failure to make available to you, any Content. You
              agree that we shall not be liable to you or to any third party for
              any modification, suspension or discontinuance of any part of the
              Service. However, if you are a subscriber for LabLog Plus, LabLog
              Premium, LabLog Business or another paid version of the Service
              (each a “Paid Service”) and find that any such modifications or
              interruption of the Paid Service adversely affects you, you may
              notify our Customer Support team, explain the adverse impact the
              modification has created and, if you desire, request a termination
              of your Paid Service. Upon receipt of any such request, we will
              endeavor to promptly remedy the adverse impact caused by the
              modification, extend the duration of your Paid Service
              subscription for a period of time equal to the interruption and/or
              refund a portion of your Paid Service subscription fee equal to
              the remaining unused term of the Paid Service subscription, as we
              determine appropriate or as may be required by applicable law.
              <br />
              <br />
              <strong>RIGHT TO ENGAGE THIRD PARTIES</strong>
              <br />
              LabLog engages certain affiliates or other third parties (“Service
              Providers”) to provide technical or other services relating to all
              or part of the Service, and you hereby agree that such involvement
              by these Service Providers is acceptable. In addition, LabLog may
              contract with third party resellers of the LabLog Plus, Premium or
              Business versions of the Service and payment processors to enable
              payments in your local currency and payment systems. Please see
              our Privacy Policy to understand the extent to which any affiliate
              or third party may have access to your account information or
              Content and our Commercial Terms to understand our relationship
              with any reseller or payment processor.
              <br />
              <br />
              <strong>RIGHT TO USE THIRD-PARTY SOFTWARE</strong>
              <br />
              LabLog may from time to time include as part of the Service and
              LabLog Software computer software supplied by third parties which
              is utilized by permission of the respective licensors and/or
              copyright holders on the terms provided by such parties. We
              provide information about some of this third party software here
              and within the particular LabLog Software. LabLog expressly
              disclaims any warranty or other assurance to you regarding such
              third party software.
              <br />
              <br />
              <strong>RIGHT TO UPDATE OUR SOFTWARE</strong>
              <br />
              In connection with any modification of the Service, LabLog may
              automatically download software updates on your computers and
              devices from time to time with the intention of improving,
              enhancing, repairing and/or further developing the Service. LabLog
              will endeavor to provide you with the option of whether or not to
              install the update; however, in certain circumstances (e.g.,
              security risks), LabLog may require you to install the update to
              continue accessing the Service. In all cases, you agree to permit
              LabLog to deliver these updates to you (and you to receive them)
              as part of your use of the Service.
              <br />
              <br />
              <strong>Do These Terms Apply to LabLog Business Users?</strong>
              <br />
              If you are using the Service as part of a LabLog Business account,
              your use of the Service is governed by these Terms, except to the
              extent the Separate Agreement governing the LabLog Business
              account provides conflicting terms. The Customer who has
              contracted with LabLog and the Administrator of the LabLog
              Business account (as “Customer” and “Administrator” are defined in
              the applicable LabLog Business Agreement) have the responsibility
              to and agree to share the terms of such Separate Agreement with
              each individual whose user account is linked to that LabLog
              Business account (“End User”). If you are an End User of an LabLog
              Business account, please note that the Customer of your LabLog
              Business account (such as your employer or organization) may have
              established its own rules regarding End Users’ access, use,
              disclosure, or retention of data stored in that account. You can
              find more information on how your personal LabLog service account
              works with your LabLog Business account in our Privacy Policy.
            </Box>
          </Typography>
        </Container>
      </>
    );
  }
}
